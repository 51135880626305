import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer'
// Removed unused imports
import Xiao1 from '../Static/popeyeskid.png';
import Xiao2 from '../Static/bruh2.png';
import Xiao3 from '../Static/bruh3.png';
import XiaoJeet from '../Static/bruh10.png';
import XiaoJeet2 from '../Static/bruh11.png';
import XiaoJeet3 from '../Static/bruh12.png';
import rocketXiao from '../Static/rocketbruh.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export default function Home() {
  return (
    <div>
      <div className='square-cover'></div>
      <Header />
      <div className='container3'>
        <p className='big-font'>THE $BRUH FUND</p>
        <p className='small-text'>THE MOST POPULAR PIECE OF SLANG IN THE WORLD. AND YOU'RE FADING IT.</p>
      </div>
      <div className='xiaoImages'>
        <div className='xiaoImg1'><img src={Xiao1} alt="2. BbQ9r6 🤵‍♂️ (dev) 18.32%" /><p className='small-text'>2. BbQ9r6 🤵‍♂️ (dev) 18.32%</p></div>
        <div className='xiaoImg2'><img src={Xiao2} alt="WHEN IRAN ATTACKS ISRAEL" /><p className='small-text'>WHEN IRAN ATTACKS ISRAEL</p></div>
        <div className='xiaoImg3'><img src={Xiao3} alt="THE ORIGINAL. THE ONE AND ONLY" /><p className='small-text'>THE ORIGINAL. THE ONE AND ONLY</p></div>
      </div>
      <div className='container2'>
        <p className='pump-button'><a className='pump-button' href='https://www.pump.fun/8NwMAzgeLMQ3H8cTqNBt8fNDUBfN1Suf1EsqF5SZK9cD' target='_blank' rel="noopener noreferrer">PUMP.FUN</a></p>
      </div>
      <div className='container4'>
        <p className='caption'>TO DEX, RAYDIUM, BINANCE, COINBASE, INFINITY AND BEYOND.</p>
        <img src={rocketXiao} alt="rocket xiao" />
      </div>
      <div className='container5'>
        <p className='caption'>BRUH IN ACTION</p>
        <Swiper
          className="mySwiper"
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={50}
          autoplay={{
            delay: 2000, // Delay in milliseconds between slides. Adjust as needed.
            disableOnInteraction: true, // Continue autoplay after user interaction
          }}        
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          effect="cards"
          style={{"--swiper-pagination-bullet-inactive-color": '#FFFFFF'}}
        >
          <SwiperSlide><img src={XiaoJeet} alt="Xiao Jeet" /><p className='small-text'>Jeffery Epstein sharing his thoughts on the $BRUH</p></SwiperSlide>
          <SwiperSlide><img src={XiaoJeet2} alt="Xiao Jeet 2" /><p className='small-text'>Man invested in a non $BRUH coin is let down by his dev</p></SwiperSlide>
          <SwiperSlide><img src={XiaoJeet3} alt="Xiao Jeet 3" /><p className='small-text'>Tyler The Creator's thoughts on PUMP.FUN</p></SwiperSlide>
        </Swiper>
      </div>
      <Footer />
    </div>
  );
}
